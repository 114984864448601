var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('img',{staticClass:"w-75",attrs:{"src":"https://assets.website-files.com/637c43f06877b684a3144f48/6387c56d6f4a3bdeb119bfde_IP_Logo_White%255B1%255D-p-500.png","alt":"IP Logo"}})]),_c('b-card-title',{staticClass:"mb-0 text-center"},[_vm._v(" Welcome to Management ")]),_c('b-card-text',{staticClass:"mb-2 text-center"},[_vm._v(" Please sign-in to your account. ")]),(_vm.stage === 'login')?_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"email","label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"login-email","state":errors.length > 0 ? false : null,"placeholder":"example@intellectualpoint.com","autofocus":""},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('b-link',{attrs:{"to":{ name: 'auth-forgot-password-v1' }}},[_c('small',{staticClass:"text-dark"},[_vm._v("Forgot Password?")])])],1),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"login-password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"remember-me","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Remember Me ")])],1),_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid}},[_vm._v(" Sign in ")])],1)]}}],null,false,1646445630)}):_vm._e(),(_vm.stage === 'request_mfa')?_c('validation-observer',{ref:"mfaForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login(false)}}},_vm._l((_vm.mfaMethods),function(mfa){return _c('b-card',{key:mfa.id,attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{attrs:{"variant":"light-info","size":"45"}},[_c('font-awesome-icon',{attrs:{"size":"2x","icon":_vm.iconOfMfaType(mfa.type)}})],1),_c('div',{staticClass:"truncate ml-1"},[_c('h4',{staticClass:"mb-25 font-weight-bolder"},[_vm._v(" "+_vm._s(mfa.name || _vm.title(mfa.type))+" ")]),_c('span',[_vm._v(_vm._s(mfa.display))])]),_c('a',{staticClass:"ml-auto",attrs:{"href":"javascript:"},on:{"click":function($event){return _vm.requestMfa(mfa)}}},[_c('b-avatar',{attrs:{"variant":"light-info","size":"35"}},[_c('font-awesome-icon',{attrs:{"size":"1x","icon":"fa-solid fa-arrow-right-from-bracket"}})],1)],1)],1)],1)}),1)]}}],null,false,4035121549)}):_vm._e(),(_vm.stage === 'submit_mfa')?_c('validation-observer',{ref:"mfaForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Verification Code","label-for":"verification-code"}},[_c('validation-provider',{attrs:{"name":"Verification Code","vid":"code","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"verification-code","type":_vm.mfa.type === 'override' ? 'password' : 'text',"state":errors.length > 0 ? false : null,"name":"verification-code","placeholder":"975871","tabindex":"1"},model:{value:(_vm.mfaCode),callback:function ($$v) {_vm.mfaCode=$$v},expression:"mfaCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":!_vm.mfaCode,"tabindex":"3"}},[_vm._v(" Sign in ")])],1)]}}],null,false,1896078959)}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }